import React from "react"
import SEO from '../components/seo';
import Layout from '../containers/layout/layout'
import Hero from '../containers/home-blog/hero'
import FeatureBlogSection from '../containers/home-blog/feature-blog'
import BlogSection from '../containers/home-blog/blog'

export default (props) => (
    <Layout headerLayout={2}>
        <SEO title="Atlasopen - Work Case Studies" description="We have experience working across a large spectrum of industries with diverse and innovative clientele. View our case studies here."/>
        <Hero/>
        <FeatureBlogSection location={props?.location?.origin}/>
        <BlogSection location={props?.location?.origin}/>
    </Layout>
)

